<template>
  <div class="wrapper finish_page">
    <h3>
      Результати тестування.&nbsp;
      <strong>{{ correctAnswersNumber }} / 26</strong>&nbsp;питань.
    </h3>
    <p v-if="correctAnswersNumber >= 25">
      <strong>23-26</strong>&nbsp;&mdash; Це&nbsp;чудовий результат! У&nbsp;вас
      є&nbsp;всі шанси успішно скласти іспит А2&nbsp;Кеу. Ви&nbsp;добре
      сприймаєте інформацію на&nbsp;слух, маєте необхідний запас лексики, знаєте
      основні граматичні структури. Проте, варто підготуватись до&nbsp;іспиту,
      щоби знати формат, стратегії і&nbsp;точно справитись з&nbsp;усіма
      завданнями.
      <a href="https://grade.ua/uk/esol/ket/#exam=ket">
        Реєструйтеся на&nbsp;іспит
      </a>
      та&nbsp;good luck! :)
    </p>
    <p v-if="correctAnswersNumber >= 18 && correctAnswersNumber <= 24">
      <strong>18-24</strong>&nbsp;&mdash; Це&nbsp;хороший результат! У&nbsp;вас
      є&nbsp;певний запас лексики та&nbsp;граматики, ви&nbsp;добре сприймаєте
      інформацію на&nbsp;слух. Ви&nbsp;можете пройти тестування та&nbsp;отримати
      не&nbsp;дуже високий результат, оскільки є&nbsp;певні прогалини
      у&nbsp;знаннях, які необхідно заповнити для успішної здачі тесту. Отже,
      варто потренуватись виконувати завдання у&nbsp;форматі іспиту, повторити
      лексику та&nbsp;граматику, ознайомитись зі&nbsp;стратегіями складання
      кожної частини екзамену.
      <a href="https://grade.ua/uk/esol/ket/#exam=ket">
        Реєструйтеся на&nbsp;іспит
      </a>
      та&nbsp;good luck! :)
    </p>
    <p v-if="correctAnswersNumber >= 11 && correctAnswersNumber <= 17">
      <strong>11-17</strong>&nbsp;&mdash; У&nbsp;вас є&nbsp;невеликий запас
      знань з&nbsp;англійської мови, за&nbsp;допомогою якого ви&nbsp;можете
      спробувати скласти іспит та&nbsp;отримати невисокий бал. Але шанси
      не&nbsp;скласти іспит, на&nbsp;жаль, досить високі. Спочатку
      <a href="https://cambridge.ua/uk/adults/#directivity=adults">
        пройдіть курс
      </a>
      загальної англійської&nbsp;А2 (Elementary) щоби покращити навички читання,
      письма, говоріння та&nbsp;аудіювання, вивчити лексику та&nbsp;граматику,
      необхідних для здачі цього іспиту.
    </p>
    <p v-if="correctAnswersNumber <= 10">
      <strong>0-10</strong>&nbsp;&mdash; На&nbsp;жаль, ви&nbsp;поки
      що&nbsp;не&nbsp;готові до&nbsp;А2&nbsp;Кеу :( Спочатку
      <a href="https://cambridge.ua/uk/adults/#directivity=adults">
        пройдіть курс
      </a>
      загальної англійської&nbsp;А2 (Elementary) щоби покращити навички читання,
      письма, говоріння та&nbsp;аудіювання, вивчити лексику та&nbsp;граматику,
      необхідних для здачі цього іспиту.
    </p>
  </div>
</template>


<script>
import { sync } from "vuex-pathify";

export default {
  name: "Finish",

  data: () => ({
    correctAnswersNumber: 0,
  }),

  computed: {
    answers: sync("tasks/answers"),
    email: sync("user/email"),
  },

  created() {
    this.answers &&
      (this.correctAnswersNumber = this.answers.flat(1).filter(Boolean).length);
  },

  mounted() {
    this.email = null;
    this.answers = null;
  },
};
</script>

<style lang="scss" scoped>
.finish_page {
  padding-top: 50px;
  padding-bottom: 50px;

  h3 {
    font-size: 22px;
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 20px;
    font-size: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>